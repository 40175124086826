const Home = () => import('../../views/Home.vue');

const routes = [
  {
    path: '/',
    component: Home,
  },
];

export default routes;
