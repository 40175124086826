<template>
  <div id="app">
    <router-view v-if="isAppReady"/>
    <Error v-if="error"/>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import * as Sentry from '@sentry/vue';

  import { localStorageGet } from './lib/localStorageUtils.js';
  import { LocalesAccount } from './i18n/locales.js';
  import accountRegions from './services/utils/accountRegions.js';

  const Error = defineAsyncComponent(() => import('./components/ErrorComponent/ErrorComponent.vue'));

  export default ({
    name: 'App',

    components: {
      Error,
    },

    data: () => ({
      isAppReady: false,
      error: false,
    }),

    computed: {
      ...mapGetters('user', ['accountRegion', 'userId', 'fullName']),
    },

    async created () {
      try {
        await this.fetchUserData();
      } catch (error) {
        this.$logError('Fetching user failed', error);
        this.error = true;
        return;
      }
      const platformName = localStorageGet('platformName');
      if (platformName != null) {
        this.updatePlatformName({ platformName });
      }
      Sentry.setUser({
        id: String(this.userId),
        username: this.fullName,
        region: this.accountRegion,
      });
      try {
        await this.seti18n();
        if (this.accountRegion === accountRegions.CL) {
          await this.fetchUserPlatforms();
        }
      } catch (error) {
        this.error = true;
        return;
      }
      try {
        await this.fetchUnleash();
      } catch (error) {
        this.$logError('Unleash failed', error);
      }
      this.isAppReady = true;
    },

    methods: {
      ...mapActions('user', ['fetchUserData', 'fetchUnleash', 'fetchUserPlatforms']),
      ...mapActions('product', ['updatePlatformName']),
      async seti18n () {
        const LOCALE = LocalesAccount[this.accountRegion];
        this.$i18n.locale = LOCALE;
        let messages;
        try {
          messages = await import(`./i18n/locales/${LOCALE}.json`);
        } catch (error) {
          try {
            messages = await import(`./i18n/locales/${LOCALE}.json`);
          } catch (error) {
            this.$logError(`Locale ${LOCALE} dynamic import failed`, error);
            throw error;
          }
        }
        this.$i18n.setLocaleMessage(LOCALE, messages.default);
      },
    },
  });
</script>

<style lang="scss">
@import './styles/colors';

*:not(input[type='text']),
*::selection:not(input[type='text']),
*:focus:not(input[type='text']),
*:active:not(input[type='text']) {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  color: $blue-500;
  text-decoration: underline;
}

.app {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.header {
  padding: 16px 16px 8px;
  margin-bottom: 8px;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 300ms;
  transition-property: height, opacity, transform;
  transition-timing-function: ease-out;
  overflow: hidden;
}

.slide-right-enter-active,
.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0);
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-leave,
.slide-left-leave {
  opacity: 1;
  transform: translateX(0);
}
</style>

<style lang="scss" src="./styles/normalize.scss" />
