import AriesSdk from '@lana/b2c-mapp-sdk';

import { logError } from '../../../lib/logger.js';
import { fetchUser } from '../../../services/aries';
import { unleash, getUnleashFlags } from '../../../lib/initUnleash.js';
import { getLanaCardStatus, getAccountLevel } from '../../../services/lending/usersApi.js';
import { getUserPlatform } from '../../../services/access/userAccessApi.js';
import { extractUserPlatform } from '../../../services/utils/product.js';

const actions = {
  /**
   * Fetches user data from AriesSDK.
   * @returns {Promise<void>}
   * @see {@link https://github.com/lana/b2c-mapp-sdk/blob/master/docs/user.md#ariessdkfetchuser}
   */
  async fetchUserData ({ commit }) {
    commit('setFullName');
    commit('setAccountPhoneNumber');
    commit('setAccountRegion');
    commit('setHasFetchUserError');

    try {
      const {
        id,
        tel,
        name: { full: fullName = '', given: givenName = '', surname = '' } = {},
        region_id: region,
      } = await fetchUser();

      commit('setUserId', id);
      commit('setFullName', fullName);
      commit('setGivenName', givenName);
      commit('setSurName', surname);
      commit('setAccountRegion', region);

      if (tel && tel.length) {
        const [{ num: phoneNumber }] = tel;
        commit('setAccountPhoneNumber', phoneNumber);
      }

      return true;
    } catch (error) {
      commit('setHasFetchUserError', true);

      logError(
        'ERROR: user store module: fetchUserData(). Error details: ',
        error,
      );

      throw error;
    }
  },
  async fetchUnleash ({ commit }) {
    await unleash.start();
    const unleashFlags = getUnleashFlags();
    const flags = unleashFlags.reduce((accumulator, { unleashName, vueName, variant }) => ({
      ...accumulator,
      [vueName]: variant ? unleash.getVariant(unleashName) : unleash.isEnabled(unleashName),
    }), {});
    commit('setUnleashFlags', flags);
  },
  async fetchLanaCardStatus ({ commit }) {
    try {
      const { card_status: cardStatus } = await getLanaCardStatus();
      commit('setLanaCardStatus', cardStatus);
    } catch (error) {
      logError('ERROR: user store module: fetchLanaCardStatus(). Error: ', error, false);
      throw error;
    }
  },
  async fetchAccountLevel ({ commit }) {
    try {
      const { account_level: accountLevel } = await getAccountLevel();
      commit('setAccountLevel', accountLevel);
    } catch (error) {
      logError('ERROR: user store module: fetchLanaCardStatus(). Error: ', error, false);
      throw error;
    }
  },
  async fetchUserPlatforms ({ commit }) {
    try {
      if (import.meta.env.DEV) {
        const mockPlatforms = import.meta.env.VITE_APP_MOCK_USER_PLATFORM;
        if (!mockPlatforms) {
          return;
        }
        const { platforms } = JSON.parse(mockPlatforms);
        commit('setUserPlatforms', platforms);
        const platform = extractUserPlatform(platforms);
        commit('setUserPlatform', platform);
        return;
      }
      const { signature } = await AriesSdk.sessionToken();
      const platforms = await getUserPlatform(signature);
      commit('setUserPlatforms', platforms);
      const userPlatform = extractUserPlatform(platforms);
      commit('setUserPlatform', userPlatform);
    } catch (error) {
      logError('ERROR: user store module: fetchUserPlatform(). Error: ', error, false);
      throw error;
    }
  },
  overwriteUserPlatform ({ commit }, platform) {
    commit('setUserPlatform', platform);
  },
  setUserKyc ({ commit }, isCompleted) {
    commit('setUserKyc', isCompleted);
  },
};

export default actions;
