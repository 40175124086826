<template>
  <div class="loading-container">
    <div class="loading-item">
      <LoadingSpinner/>
    </div>
    <TextParagraph v-if="message">
      {{ message }}
    </TextParagraph>
  </div>
</template>

<script>
  import { LoadingSpinner, TextParagraph } from '@lana/b2c-mapp-ui';

  export default ({
    name: 'Loading',

    components: {
      LoadingSpinner,
      TextParagraph,
    },
    props: {
      message: {
        type: String,
        default: '',
      },
    },
  });
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-item {
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}
</style>
