import { logError } from '../../lib/logger.js';
import { fetchGet, fetchPost } from '../utils/fetchApi.js';
import store from '../../store';

const getUserId = () => store.getters['user/userId'];

const createExtraInfo = async (body, extraInfoType) => {
  /**
    * @description - Create extra info
    * @param body - The files to upload
    * @param extraInfoType - Type of extrainfo
    * @memberof LendingService
    * @see {@link https://lending.lana.credit/apidoc/#users-create_extra_info}
  */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/users/${getUserId()}/extra-info/${extraInfoType}/`;
  try {
    const response = await fetchPost(uri, { body, formData: true });
    return response;
  } catch (error) {
    logError('ERROR: Lending Service - createExtraInfo() - error: ', error, false);
    throw error;
  }
};

const fetchUserLastCreditRequestStatus = async (categoryType = null) => {
  /**
    * @description - Fetch user last credit request status
    * @param {string} userId - User id
    * @param {string} categoryType - If categoryType is omitted, API is gonna return the last credit either
    * approved or pending. If categoryType is provided, API is gonna return if there is a rejected credit of
    * the specified category
    * @returns {Promise<void | IUserLastCreditRequestStatus>} - User last credit request status
    * @memberof LendingService
    * @see {@link https://lending.lana.credit/user/USER_ID/credit-requests/last}
  */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/users/${getUserId()}/credit-requests/last${(categoryType != null)
    ? `?category_type=${categoryType}`
    : ''}`;
  try {
    const userCreditRequests = await fetchGet(uri);
    return userCreditRequests;
  } catch (error) {
    logError('ERROR: Lending Service - fetchUserLastCreditRequestStatus() - error: ', error, false);
    throw error;
  }
};

const getAccountLevel = async () => {
  /**
    * @description - Get user account level
    * @param userId - The user ID
    * @returns Account level
    * @memberof LendingService
    * https://lending.lana.credit/apidoc/#users-account_level
  */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/users/${getUserId()}/account-level/`;
  try {
    const accountLevel = await fetchGet(uri);
    return accountLevel;
  } catch (error) {
    logError('ERROR: Lending Service - getAccountLevel() - error: ', error, false);
    throw error;
  }
};

const getUserExtraInfos = async (type) => {
  /**
    * @description - Get user extra infos
    * @param userId - The user ID
    * @param type - Extra info type
    * @returns Extra infos list
    * @memberof LendingService
    * https://lending.lana.credit/apidoc/#users-create_extra_info
  */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/users/${getUserId()}/extra-info/${type ? `?type=${type}` : ''}`;
  try {
    const extraInfos = await fetchGet(uri);
    return extraInfos;
  } catch (error) {
    logError('ERROR: Lending Service - getUserExtraInfos() - error: ', error, false);
    throw error;
  }
};

const getLanaCardStatus = async () => {
  /**
    * @description - Get user lana card status
    * @param userId - The user ID
    * @returns Status Lana Card
    * @memberof LendingService
    * https://lending.lana.credit/apidoc/#users-card_status
  */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/users/${getUserId()}/card-status/`;
  try {
    const cardStatus = await fetchGet(uri);
    return cardStatus;
  } catch (error) {
    logError('ERROR: Lending Service - getLanaCardStatus() - error: ', error, false);
    throw error;
  }
};

const getAccountStatus = async () => {
  /**
    * @description - Get user lana account status
    * @returns Status status account
    * @memberof LendingService
    * https://lending.lana.credit/apidoc/#account-status
  */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/users/${getUserId()}/account-status/`;
  try {
    const accountStatus = await fetchGet(uri);
    return accountStatus;
  } catch (error) {
    logError('ERROR: Lending Service - getAccountStatus() - error: ', error, false);
    throw error;
  }
};

export {
  createExtraInfo,
  fetchUserLastCreditRequestStatus,
  getAccountLevel,
  getUserExtraInfos,
  getLanaCardStatus,
  getAccountStatus,
};
