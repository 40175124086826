const initialState = {
  products: [],
  platformName: '',
  wasCreditRequested: false,
  userLastCreditRequestStatus: {},
  userRejectedLoan: {},
  selectedProduct: {},
  nextUrl: null,
  blockedProduct: null,
};

export default initialState;
