const Request = () => import('../../views/Request/Request.vue');
const RequestContracts = () => import('../../views/RequestContracts/RequestContracts.vue');
const RequestContractViewer = () => import('../../views/RequestContractViewer/RequestContractViewer.vue');
const RequestIncome = () => import('../../views/RequestIncome/RequestIncome.vue');
const RequestLoanPurpose = () => import('../../views/RequestLoanPurpose/RequestLoanPurpose.vue');

const routes = [
  {
    path: '/request',
    name: 'request',
    component: Request,
    meta: { requiresProduct: true },
    children: [{
      path: 'contracts',
      name: 'request/contracts',
      component: RequestContracts,
      meta: { requiresProduct: true },
    }, {
      path: 'contracts-viewer',
      name: 'request/contractViewer',
      component: RequestContractViewer,
      meta: { requiresProduct: true },
    }, {
      path: 'weekly-income',
      name: 'request/weeklyIncome',
      component: RequestIncome,
      meta: { requiresProduct: true },
    }, {
      path: 'personal-question',
      name: 'request/personalQuestion',
      component: RequestLoanPurpose,
      meta: { requiresProduct: true },
    }],
  },
];

export default routes;
