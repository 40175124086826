import { createRouter, createWebHashHistory } from 'vue-router';

import store from '../store';
import routes from './routes';
import { PRODUCT_CATEGORY } from '../services/utils/product.js';
import REGIONS from '../services/utils/accountRegions.js';
import { getAccountStatus } from '../services/lending/usersApi.js';
import { logError } from '../lib/logger';

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  if (!store.getters['user/accountRegion']) {
    await store.dispatch('user/fetchUserData');
    await store.dispatch('user/fetchUnleash');
  }
  switch (true) {
    case (to.meta.requiresKYC && store.getters['user/accountRegion'] === REGIONS.CL):
      if (store.getters['user/isKycCompleted'] || import.meta.env.DEV || !store.getters['user/unleashFlags'].clAccountRequired) {
        return true;
      }
      try {
        const { response: { has_activated_account: activatedAccount } = {} } = await getAccountStatus();
        if (!activatedAccount) {
          return { name: 'product/incompleteKyc', replace: true };
        }
        store.dispatch('user/setUserKyc', activatedAccount);
      } catch (error) {
        logError('ERROR: beforeEach - getAccountStatus - error: ', error);
        store.dispatch('user/setUserKyc', true);
      }
      break;
    case to.meta.requiresProduct && !store.getters['product/isSelectedProduct']:
      return { name: 'product/info', params: { category: PRODUCT_CATEGORY.SCORE_BUILDER }, replace: true };
  }
});

export default router;
