<template>
  <Loading v-if="loading"
           :message="loadingMessage"
  />
  <Error v-if="!loading && error"
         :message="errorMessage"
  />
  <Screen v-if="!loading && !error">
    <ScrollWrapper v-if="scroll">
      <slot/>
    </ScrollWrapper>
    <div v-if="!scroll">
      <slot/>
    </div>

    <!--Action button-->
    <WrappedButton v-if="buttonVisible"
                   :class="{ action: buttonShadow }"
                   data-test-id="cta"
                   :disabled="buttonDisabled"
                   :loading="buttonLoading"
                   :type="buttonType"
                   @click="action"
    >
      {{ buttonText }}
    </WrappedButton>
  </Screen>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import {
    Screen,
    ScrollWrapper,
    WrappedButton,
  } from '@lana/b2c-mapp-ui';
  import AriesSdk from '@lana/b2c-mapp-sdk';

  import Loading from '../Loading/Loading.vue';

  const Error = defineAsyncComponent(() => import('../ErrorComponent/ErrorComponent.vue'));

  export default ({
    name: 'LendingBase',

    components: {
      Screen,
      ScrollWrapper,
      WrappedButton,
      Loading,
      Error,
    },

    props: {
      buttonVisible: {
        type: Boolean,
        default: false,
      },
      buttonDisabled: {
        type: Boolean,
        default: false,
      },
      buttonText: {
        type: String,
        default: '',
      },
      buttonLoading: {
        type: Boolean,
        default: false,
      },
      buttonType: {
        type: String,
        default: '',
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingMessage: {
        type: String,
        default: '',
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        default: null,
      },
      scroll: {
        type: Boolean,
        default: true,
      },
      dismissIcon: {
        type: String,
        default: 'back',
      },
      barTitle: {
        type: String,
        default: '',
      },
      supportIcon: {
        type: Boolean,
        default: false,
      },
      loaded: {
        type: Boolean,
        default: false,
      },
      buttonShadow: {
        type: Boolean,
        default: true,
      },
    },

    emits: ['action'],

    mounted () {
      AriesSdk.setWebViewDismissIcon(this.dismissIcon);
      AriesSdk.setAppBarTitle(this.barTitle);
      AriesSdk.showSupportIcon(this.supportIcon);
      if (this.loaded) {
        AriesSdk.webViewLoaded();
      }
    },

    methods: {
      action () {
        this.$emit('action');
      },
    },
  });
</script>

<style lang="scss" scoped>
@import '../../styles/colors';

.action {
  box-shadow: 0px -2px 32px $black-alpha;
}
</style>
