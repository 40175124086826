const mutations = {
  setUserId (state, userId = '') {
    state.userId = userId;
  },

  setFullName (state, fullName = '') {
    state.fullName = fullName;
  },

  setGivenName (state, givenName = '') {
    state.givenName = givenName;
  },

  setSurName (state, surName = '') {
    state.surName = surName;
  },

  setAccountPhoneNumber (state, phoneNumber = '') {
    state.accountPhoneNumber = phoneNumber;
  },

  setAccountRegion (state, region = '') {
    state.accountRegion = region;
  },

  setHasFetchUserError (state, hasError = false) {
    state.hasFetchUserError = hasError;
  },

  setUnleashFlags (state, unleashFlags) {
    state.unleashFlags = unleashFlags;
  },

  setLanaCardStatus (state, lanaCardActive) {
    state.lanaCardActive = lanaCardActive;
  },

  setAccountLevel (state, accountLevel) {
    state.accountLevel = accountLevel;
  },

  setUserPlatform (state, platform) {
    if (!platform) {
      return;
    }
    state.userPlatform = platform;
  },

  setUserPlatforms (state, platforms) {
    if (!Array.isArray(platforms)) {
      return;
    }
    state.userPlatforms = platforms;
  },

  setUserKyc (state, isCompleted) {
    state.isKycCompleted = isCompleted;
  },
};

export default mutations;
