const initialState = {
  userId: '',
  fullName: '',
  givenName: '',
  surName: '',
  accountPhoneNumber: '',
  accountRegion: '',
  hasFetchUserError: false,
  unleashFlags: {},
  lanaCardActive: null,
  accountLevel: '',
  userPlatform: null,
  userPlatforms: [],
  isKycCompleted: false,
};

export default initialState;
