import { logError } from '../../lib/logger.js';
import { fetchGet } from '../utils/fetchApi.js';

const fetchBlockedProduct = async (productId, metabaseQuestion = null) => {
  /**
      * @description - Fetch if product is blocked or not
      * @param {productId} - The product ID
      * @memberof LendingService
      * @see {@link https://lending.lana.credit/apidoc/#products-is_blocked
    */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/products/${productId}/is-blocked/${metabaseQuestion ? `?metabase-question=${metabaseQuestion}` : ''}`;
  try {
    const blockedProduct = await fetchGet(uri);
    return blockedProduct;
  } catch (error) {
    logError('ERROR: Lending Service - fetchBlockedProduct() - error: ', error, false);
    throw error;
  }
};

const fetchProduct = async (productId) => {
  /**
      * @description - Fetch product
      * @param {productId} Product - The product ID
      * @returns {Promise<void | Product[]>} - Product
      * @memberof LendingService
      * @see {@link https://lending.lana.credit/apidoc/#products-read}
    */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/products/${productId}/`;
  try {
    const product = await fetchGet(uri);
    return product;
  } catch (error) {
    logError('ERROR: Lending Service - fetchProduct() - error: ', error, false);
    throw error;
  }
};

const fetchProductList = async (category) => {
  /**
      * @description - Fetch product list
      * @param {PRODUCT_CATEGORY} category - The category of the product
      * @returns {Promise<void | IProduct[]>} - Product list
      * @memberof LendingService
      * @see {@link https://lending.lana.credit/apidoc/#products-list}
    */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/products/?category=${category}&ordering=name`;
  try {
    const productList = await fetchGet(uri);
    return productList;
  } catch (error) {
    logError('ERROR: Lending Service - fetchProductList() - error: ', error, false);
    throw error;
  }
};

const fetchUserElegibleLoan = async (productId) => {
  /**
      * @description - Fetch user elegible loan
      * @param {productId} category - The product ID
      * @returns {Promise<void | boolean>} - User elegible loan
      * @see {@link https://lending.lana.credit/apidoc/#products-eligible}
    */
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/products/${productId}/eligible/`;
  try {
    const userElegibleLoan = await fetchGet(uri);
    return userElegibleLoan;
  } catch (error) {
    logError('ERROR: Lending Service - fetchUserElegibleLoan() - error: ', error, false);
    throw error;
  }
};

const fetchProductContracts = async (productId = null) => {
  const uri = `${import.meta.env.VITE_APP_LENDING_API}/products/${productId}/contracts/`;
  try {
    const productContracts = await fetchGet(uri);
    return productContracts;
  } catch (error) {
    logError('ERROR: Lending Service - fetchProductContracts() - error: ', error, false);
    throw error;
  }
};

export {
  fetchBlockedProduct,
  fetchProduct,
  fetchProductList,
  fetchUserElegibleLoan,
  fetchProductContracts,
};
