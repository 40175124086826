import { UnleashClient } from 'unleash-proxy-client';

// In case unleash flags want to be used in local a unleash proxy needs to be mounted
// in your computer, you can follow these steps https://docs.getunleash.io/sdks/unleash-proxy#payload

const unleash = new UnleashClient({
  url: import.meta.env.VITE_APP_UNLEASH_URI,
  clientKey: import.meta.env.VITE_APP_UNLEASH_PROXY_SECRETS,
  appName: import.meta.env.VITE_APP_UNLEASH_APP_NAME,
  disableRefresh: true,
});

const unleashFlags = Object.freeze([{
  unleashName: 'request_identity_document',
  vueName: 'identityDocument',
  variant: false,
}, {
  unleashName: 'personal_references',
  vueName: 'personalReferences',
  variant: false,
}, {
  unleashName: 'new_score_builder_flow',
  vueName: 'newSbFlow',
  variant: false,
}, {
  unleashName: 'video_recording',
  vueName: 'recordVideo',
  variant: false,
}, {
  unleashName: 'ask_user_contacts_on_credit_request_flow',
  vueName: 'askContacts',
  variant: false,
}, {
  unleashName: 'is_platform_details_vue_enabled',
  vueName: 'detailsEnabled',
  variant: false,
}, {
  unleashName: 'is_contracts_vue_enabled',
  vueName: 'contractsEnabled',
  variant: false,
}, {
  unleashName: 'is_cl_account_required',
  vueName: 'clAccountRequired',
  variant: false,
}, {
  unleashName: 'is_cl_market_closed',
  vueName: 'isClMarketClosed',
  variant: false,
}, {
  unleashName: 'is_mx_market_closed',
  vueName: 'isMxMarketClosed',
  variant: false,
}]);

const getUnleashFlags = (name) => {
  if (name) {
    return unleashFlags.find(({ unleashName, vueName }) => [unleashName, vueName].includes(name));
  }
  return unleashFlags;
};

export { unleash, getUnleashFlags };
