import store from '../../store';
const applicationJsonContentType = 'application/json';

const getHeaders = ({ formData = false, JWT = '' }) => {
  const extraOptions = JWT ? { 'X-User-JWT': JWT } : store.getters['user/extraOptions'];
  return {
    ...(!formData && { 'Content-Type': applicationJsonContentType }),
    Accept: applicationJsonContentType,
    ...extraOptions,
  };
};

const fetchJSON = async (endpoint, options = {}) => {
  const response = await fetch(endpoint, {
    ...options,
  });

  const text = await response.text();
  const data = text && JSON.parse(text);

  if (!response.ok) {
    const error = (data && data.message) || response.statusText;
    throw new Error(error);
  }

  return data;
};

const fetchGet = (endpoint, { JWT = '' } = {}) => {
  const headers = getHeaders({ JWT });
  return fetchJSON(endpoint, {
    headers,
    method: 'GET',
  });
};

const fetchPost = (endpoint, { body, JWT = '', formData = false } = {}) => {
  const headers = getHeaders({ JWT, formData });
  return fetchJSON(endpoint, {
    headers,
    body: body ? (formData ? body : JSON.stringify(body)) : undefined,
    method: 'POST',
  });
};

const fetchPatch = (endpoint, { body, JWT = '' } = {}) => {
  const headers = getHeaders({ JWT });
  return fetchJSON(endpoint, {
    headers,
    body: JSON.stringify(body),
    method: 'PATCH',
  });
};

const fetchPut = (endpoint, { body, JWT = '' } = {}) => {
  const headers = getHeaders({ JWT });
  return fetchJSON(endpoint, {
    headers,
    body: JSON.stringify(body),
    method: 'PUT',
  });
};

const fetchDelete = (endpoint, { JWT = '' } = {}) => {
  const headers = getHeaders({ JWT });
  return fetchJSON(endpoint, {
    headers,
    method: 'DELETE',
  });
};

export {
  fetchJSON,
  fetchGet,
  fetchPost,
  fetchPatch,
  fetchPut,
  fetchDelete,
};
