function checkCreditCategoryKey (data) {
  return data.findIndex((item) => item === 'category') !== -1;
}

function checkCreditKeys (items) {
  const existsKeys = items.findIndex(
    (item) => item === 'status' || item === 'product',
  );

  return existsKeys !== -1;
}

export default function checkCredit (data) {
  const items = Object.keys(data);
  const existsCredit = checkCreditKeys(items);

  if (existsCredit) {
    const productKeys = Object.keys(data.product);
    const existsCategory = checkCreditCategoryKey(productKeys);
    return existsCategory;
  }

  return false;
}
