/* eslint-disable no-console  */
import * as Sentry from '@sentry/vue';

const logWarning = (message, ...logArguments) => {
  console.warn(message, ...logArguments);
};

const logError = (message, error = {}, sentryCapture = true) => {
  console.error(message, error);
  if (error.message == null || error.message === '') {
    // eslint-disable-next-line no-param-reassign
    error.message = message;
  }
  if (sentryCapture) {
    Sentry.captureException(error);
  }
};

const logInfo = (message, ...logArguments) => {
  console.info(message, ...logArguments);
};

export { logError, logInfo, logWarning };
