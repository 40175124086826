import { createI18n } from 'vue-i18n';

import { Locales } from './locales';
import numberFormats from './locales/numberFormats';
import defaultTranslation from './locales/es-mx.json';

const defaultLocale = Locales.esMX;

const defaultMessages = {
  [defaultLocale]: defaultTranslation,
};

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: defaultMessages,
  numberFormats,
});

export {
  defaultLocale,
};
export default i18n;
