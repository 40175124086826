import i18n from '../../i18n';

const blockedReasons = Object.freeze({
  seniority: 'seniority',
  card_expenses: 'cardExpenses',
  weeks_income: 'weeksIncome',
  active_loan: 'activeLoan',
  bring_payments: 'bringPayments',
});

const reasonTexts = Object.freeze({
  seniority: {
    values: ['weeks'],
    title: 'criteria_seniority_title',
    description: 'criteria_seniority_description',
    icon: 'CalendarColorIcon',
  },
  cardExpenses: {
    values: ['spend_total'],
    title: 'criteria_card_expenses_title',
    description: 'criteria_card_expenses_description',
    icon: 'CardMinimalIcon',
    link: {
      text: 'criteria_not_card_yet',
      action: 'activeCard',
    },
  },
  weeksIncome: {
    values: ['weeks', 'amount'],
    title: 'criteria_weeks_income_title',
    description: 'criteria_weeks_income_description',
    icon: 'MicroDepositMini',
    link: {
      text: 'criteria_deposit',
      action: 'deposit',
    },
  },
  bringPayments: {
    values: ['weeks', 'amount'],
    title: 'criteria_weeks_income_title',
    description: 'criteria_weeks_income_description',
    icon: 'ColorWalletIcon',
    link: {
      text: 'criteria_not_payments_yet',
      action: 'bringPayments',
    },
  },
  activeLoan: {
    title: 'criteria_active_loan_title',
    description: 'criteria_active_loan_desription',
    icon: 'MoneyBagColorIcon',
  },
});

const interpolate = (criteria, reason) => {
  if (!criteria) {
    return null;
  }
  const { title, description, values, icon, link } = reasonTexts[reason];
  const interpolatedReason = {
    title: i18n.global.t(title),
    description: '',
    ...(icon) && { icon },
    ...(link) && { link },
  };
  if (!values || !Array.isArray(values) || values.length < 1) {
    interpolatedReason.description = i18n.global.t(description);
    return interpolatedReason;
  }
  const parsedCriteria = JSON.parse(criteria);
  const interpolations = values.reduce((accumulator, value) =>
    ({ ...accumulator,
      [value]: ['spend_total', 'amount'].includes(value)
        ? i18n.global.n(parseInt(parsedCriteria[value]), 'currency')
        : parsedCriteria[value] }),
  {});
  interpolatedReason.description = i18n.global.t(description, interpolations);
  return interpolatedReason;
};

const PRODUCT_STATUS = Object.freeze({
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
});

const PRODUCT_CATEGORY = Object.freeze({
  PLATFORM: 'platform',
  SCORE_BUILDER: 'score_builder',
  PROFESSIONAL: 'professional',
  'papa-johns': 'papa_johns',
  'just-burger': 'just_burger',
  'time-jobs': 'time_jobs',
  cabify: 'cabify',
  peya: 'peya',
  gigNonAlliance: 'gig-non-alliance',
});

const campaigns = Object.freeze({
  'papa-johns': {
    title: 'exclusive_loan_apply',
    description: 'exclusive_loan_campaign',
    icon: 'SheepPapaJohnsIcon',
  },
  'just-burger': {
    title: 'exclusive_loan_apply',
    description: 'exclusive_loan_campaign',
    icon: 'SheepJustBurger',
  },
  'time-jobs': {
    title: 'exclusive_loan_apply',
    description: 'exclusive_loan_campaign',
    icon: 'SheepTimeJobs',
  },
  cabify: {
    title: 'exclusive_loan_apply',
    description: 'exclusive_loan_campaign',
    icon: 'SheepCabify',
  },
  peya: {
    title: 'exclusive_loan_apply',
    description: 'exclusive_loan_campaign',
    icon: 'SheepPedidosYa',
  },
});

const nonAllaincePlatforms = Object.freeze([
  'uber',
  'uber-eats',
  'indriver',
  'beat',
  'didi',
  'rappi',
  'cornershop',
  'picap',
]);

const transformReasons = (productCriteria) => {
  const criteria = Object.keys(blockedReasons).reduce((accumulator, property) => {
    if (!productCriteria[property]) {
      return accumulator;
    }
    const blockedReason = blockedReasons[property];
    return { ...accumulator, [blockedReasons[property]]: interpolate(productCriteria[property], blockedReason) };
  }, {});
  return criteria;
};

const getStaticURL = (region, category) => {
  switch (category) {
    case PRODUCT_CATEGORY.SCORE_BUILDER:
    case PRODUCT_CATEGORY['papa-johns']:
    case PRODUCT_CATEGORY['just-burger']:
    case PRODUCT_CATEGORY['time-jobs']:
    case PRODUCT_CATEGORY.cabify:
    case PRODUCT_CATEGORY.peya:
    case PRODUCT_CATEGORY.gigNonAlliance:
      return `https://landing.lana.xyz/${region}-market/prestamo-confianza`;
    case PRODUCT_CATEGORY.PLATFORM:
      return `https://landing.lana.xyz/${region}-market/prestamo-plataformas`;
    default: return '';
  }
};

const getCampaigns = () => {
  return Object.keys(campaigns);
};

const extractUserPlatform = (platforms = []) => {
  if (!Array.isArray(platforms) || !platforms.length) {
    return null;
  }
  switch (true) {
    case getCampaigns().includes(platforms[0]):
      return platforms[0];
    case isNonAllianceGig(platforms):
      return 'gigNonAlliance';
    default:
      return null;
  }
};

const userPlatformByCategory = (category) => {
  return Object.keys(PRODUCT_CATEGORY).find((key) => PRODUCT_CATEGORY[key] === category);
};

const isNonAllianceGig = (userPlatforms = []) => {
  if (!userPlatforms.length) {
    return false;
  }
  return userPlatforms.some((platform) => nonAllaincePlatforms.indexOf(platform) >= 0);
};

export {
  PRODUCT_STATUS,
  PRODUCT_CATEGORY,
  campaigns,
  transformReasons,
  getStaticURL,
  getCampaigns,
  extractUserPlatform,
  userPlatformByCategory,
  isNonAllianceGig,
};
