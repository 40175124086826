const Product = () => import('../../views/Product/Product.vue');
const ProductsList = () => import('../../views/ProductsList/ProductsList.vue');
const ProductNotAbleToRequest = () => import('../../views/ProductNotAbleToRequest/ProductNotAbleToRequest.vue');
const ProductDetail = () => import('../../views/ProductDetail/ProductDetail.vue');
const ProductInfo = () => import('../../views/ProductInfo/ProductInfo.vue');
const PlatformSelection = () => import('../../views/PlatformSelection/PlatformSelection.vue');
const ProductInterest = () => import('../../views/ProductInterest/ProductInterest.vue');
const CampaignScreen = () => import('../../views/CampaignScreen/CampaignScreen.vue');
const IncompleteKyc = () => import('../../views/IncompleteKyc/IncompleteKyc.vue');

const routes = [
  {
    path: '/product',
    component: Product,
    name: 'product',
    props: true,
    meta: { requiresKYC: true },
    children: [{
      path: ':category',
      name: 'product/info',
      component: ProductInfo,
      props: true,
      meta: { requiresKYC: true },
    }, {
      path: ':category/campaign',
      name: 'product/campaign',
      component: CampaignScreen,
      props: true,
      meta: { requiresKYC: true },
    }, {
      path: ':category/selection',
      name: 'platform/selection',
      component: PlatformSelection,
      meta: { requiresKYC: true },
    },
    {
      path: ':category/list',
      name: 'products/list',
      component: ProductsList,
      props: true,
      meta: { requiresKYC: true },
    },
    {
      path: ':category/:id/details',
      name: 'product/details',
      component: ProductDetail,
      props: true,
      meta: { requiresKYC: true },
    },
    {
      path: ':category/:id/interest',
      name: 'product/interest',
      component: ProductInterest,
      props: true,
      meta: { requiresKYC: true },
    }],
  },
  {
    path: '/not-able-to-request',
    name: 'product/notAbleToRequest',
    component: ProductNotAbleToRequest,
  },
  {
    path: '/incomplete-kyc',
    name: 'product/incompleteKyc',
    component: IncompleteKyc,
  },
];

export default routes;
