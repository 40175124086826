const mutations = {
  setUserLastCreditRequestStatus (state, userLastCreditRequestStatus) {
    state.userLastCreditRequestStatus = userLastCreditRequestStatus;
  },

  setWasCreditRequested (state, wasCreditRequested = false) {
    state.wasCreditRequested = wasCreditRequested;
  },

  setPlatformName (state, platformName = '') {
    state.platformName = platformName;
  },

  setProducts (state, products = []) {
    state.products = products;
  },

  setLastRejectedLoan (state, rejectedLoan) {
    state.userRejectedLoan = rejectedLoan;
  },

  setSelectedProduct (state, selectedProduct) {
    state.selectedProduct = selectedProduct;
  },

  setNextUrl (state, nextUrl) {
    state.nextUrl = nextUrl;
  },

  setBlockedProduct (state, blockedProduct) {
    state.blockedProduct = blockedProduct;
  },
};

export default mutations;
