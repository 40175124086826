import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export const initSentry = (router, app) => {
  Sentry.init({
    app,
    // eslint-disable-next-line no-undef
    release: `${VITE_APP_NAME}@${VITE_APP_VERSION}`,
    environment: import.meta.env.VITE_APP_ENV,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracingOptions: {
      trackComponents: true,
    },
    // Ignore view.show-support-icon not supported
    ignoreErrors: ['Non-Error promise rejection captured with keys'],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: 0.05,
    attachProps: true,
    attachStacktrace: true,
  });
};
