import { logError } from '../../lib/logger.js';
import { fetchGet } from '../utils/fetchApi.js';

const getUserPlatform = async (signature) => {
  /**
      * @description - Get user's platforms
    */
  const uri = `${import.meta.env.VITE_APP_ACCESS_API}/user/platform`;
  try {
    const { platforms } = await fetchGet(uri, { JWT: signature });
    return platforms;
  } catch (error) {
    if (error.message === 'Not Found') {
      return null;
    }
    logError('ERROR: Access Service - getUserPlatform() - error: ', error, false);
    throw error;
  }
};

export { getUserPlatform };
