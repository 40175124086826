import { createStore } from 'vuex';

import user from './modules/user';
import product from './modules/product';

const modules = {
  user,
  product,
};

const storeOptions = {
  strict: true,
  modules,
};

export default createStore(storeOptions);
