const CreditRejected = () => import('../../views/CreditRejected/CreditRejected.vue');
const Credit = () => import('../../views/Credit/Credit.vue');

const routes = [
  {
    path: '/credit',
    name: 'credit',
    component: Credit,
    children: [
      {
        path: 'rejected',
        name: 'credit/rejected',
        component: CreditRejected,
      },
    ],
  },
];

export default routes;
