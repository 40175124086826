const numberFormats = Object.freeze({
  'es-mx': {
    currency: {
      style: 'currency',
      currency: 'MXN',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  'es-cl': {
    currency: {
      style: 'currency',
      currency: 'CLP',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
});

export default numberFormats;
