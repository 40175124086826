const Locales = Object.freeze({
  esMX: 'es-mx',
  esCL: 'es-cl',
});

const LOCALES = Object.freeze([
  { value: Locales.esMX, caption: 'México' },
  { value: Locales.esCL, caption: 'Chile' },
]);

const LocalesAccount = Object.freeze({
  MX: 'es-mx',
  CL: 'es-cl',
});

export {
  Locales,
  LOCALES,
  LocalesAccount,
};
