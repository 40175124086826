import initialState from './initialState';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const storeModule = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};

export default storeModule;
