import { logError } from '../../../lib/logger.js';
import { fetchUserLastCreditRequestStatus } from '../../../services/lending/usersApi.js';
import { fetchBlockedProduct, fetchProductList, fetchProduct } from '../../../services/lending/productsApi.js';
import { localStorageStore, localStorageRemove } from '../../../lib/localStorageUtils.js';
import { transformReasons } from '../../../services/utils/product.js';

const actions = {
  updateWasCreditRequested (context, wasCreditRequested) {
    const { commit } = context;
    commit('setWasCreditRequested', wasCreditRequested);
  },

  updatePlatformName (context, { platformName, updateLocalStorage = false }) {
    const { commit } = context;
    commit('setPlatformName', platformName);
    if (updateLocalStorage) {
      localStorageStore('platformName', platformName);
    }
  },

  clearCreditData (context) {
    const { commit } = context;

    commit('setWasCreditRequested', false);
    commit('setPlatformName', '');
    localStorageRemove('platformName');
  },

  async obtainUserLastCreditRequestStatus (context) {
    const { commit } = context;

    try {
      const userCreditRequests = await fetchUserLastCreditRequestStatus();
      commit('setUserLastCreditRequestStatus', userCreditRequests);
    } catch (error) {
      logError('ERROR: product store module: obtainUserLastCreditRequestStatus(). Error: ', error, false);
      throw error;
    }
  },

  async obtainProductList (context, category) {
    const { commit } = context;

    try {
      const productList = await fetchProductList(category);
      commit('setProducts', productList);
    } catch (error) {
      logError('ERROR: product store module: obtainProductList(). Error: ', error, false);
      throw error;
    }
  },

  async getRejectedLoan (context, categoryType) {
    const { commit } = context;

    try {
      const rejectedLoan = await fetchUserLastCreditRequestStatus(categoryType);
      commit('setLastRejectedLoan', rejectedLoan);
    } catch (error) {
      logError('ERROR: product store module: getRejectedLoan(). Error: ', error, false);
      throw error;
    }
  },

  async getProduct ({ commit, getters }, productId) {
    if (Array.isArray(getters.products) && !!getters.products.length) {
      const selectedProduct = getters.products.find(({ uuid }) => uuid === productId);
      commit('setSelectedProduct', selectedProduct || {});
      return;
    }
    try {
      const selectedProduct = await fetchProduct(productId);
      commit('setSelectedProduct', selectedProduct || {});
    } catch (error) {
      logError('ERROR: product store module: getProduct(). Error: ', error, false);
      throw error;
    }
  },

  setNextUrl ({ commit }, nextUrl) {
    commit('setNextUrl', nextUrl);
  },

  async getBlockedProduct ({ commit }, { productId, metabaseQuestion }) {
    try {
      const response = await fetchBlockedProduct(productId, metabaseQuestion);
      if (!Array.isArray(response) || response.length < 1) {
        return commit('setBlockedProduct', null);
      }
      const criteria = transformReasons(response[0]);
      commit('setBlockedProduct', criteria);
    } catch (error) {
      logError('ERROR: product store module: getBlockedProduct(). Error: ', error, false);
      throw error;
    }
  },
};

export default actions;
