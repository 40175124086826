const IncomeSource = () => import('../../views/IncomeSource/IncomeSource.vue');
const MultiIncome = () => import('../../views/MultiIncome/MultiIncome.vue');
const IncomeApp = () => import('../../views/IncomeApp/IncomeApp.vue');
const IncomeDocument = () => import('../../views/IncomeDocument/IncomeDocument.vue');

const routes = [{
  path: '/income-source',
  name: 'incomeSource',
  component: IncomeSource,
  props: true,
  children: [{
    path: 'multi-income',
    name: 'incomeSource/multi',
    component: MultiIncome,
  }, {
    path: 'connect-app',
    name: 'incomeSource/palenca',
    component: IncomeApp,
  }, {
    path: 'document',
    name: 'incomeSource/document',
    component: IncomeDocument,
  }],
}];

export default routes;
