function localStorageGet (key) {
  let item;
  try {
    item = window.localStorage.getItem(key);
    return JSON.parse(item);
  } catch (ex) {
    // localStorage not supported, ignore or the item returned is not in JSON format
    return item || null;
  }
}

function localStorageStore (key, value) {
  try {
    const stringifiedValue = (typeof value !== 'string') ? JSON.stringify(value) : value;
    window.localStorage.setItem(key, stringifiedValue);
  } catch (ex) {
    // localStorage not supported, ignore
  }
}

function localStorageRemove (key) {
  try {
    window.localStorage.removeItem(key);
  } catch (ex) {
    // localStorage not supported, ignore
  }
}

export {
  localStorageGet,
  localStorageStore,
  localStorageRemove,
};
