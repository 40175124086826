import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { initSentry } from './lib/initSentry.js';
import LendingBase from './components/LendingBase/LendingBase.vue';
import { logError } from './lib/logger';

import '@lana/b2c-mapp-ui/dist/style.css';

const app = createApp(App);

if (!import.meta.env.DEV) {
  initSentry(router, app);
}

app.component('LendingBase', LendingBase);
app.config.globalProperties.$logError = logError;

app.use(store).use(router).use(i18n).mount('#app');
