const getters = {
  userId: ({ userId }) => userId,

  fullName: ({ fullName }) => fullName,

  givenName: ({ givenName }) => givenName,

  surName: ({ surName }) => surName,

  accountPhoneNumber: ({ accountPhoneNumber }) => accountPhoneNumber,

  accountRegion: ({ accountRegion }) => accountRegion,

  hasFetchUserError: ({ hasFetchUserError }) => hasFetchUserError,

  extraOptions: (state) => {
    const data = {
      user: state.userId,
      country: state.accountRegion,
    };
    return data;
  },

  unleashFlags: ({ unleashFlags }) => unleashFlags,

  lanaCardActive: ({ lanaCardActive }) => lanaCardActive,

  accountLevel: ({ accountLevel }) => accountLevel,

  userPlatform: ({ userPlatform }) => userPlatform,

  userPlatforms: ({ userPlatforms }) => userPlatforms,

  isKycCompleted: ({ isKycCompleted }) => isKycCompleted,
};

export default getters;
