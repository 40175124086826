import checkCredit from '../../../lib/checkCredit.js';
import { PRODUCT_CATEGORY, PRODUCT_STATUS } from '../../../services/utils/product.js';

const getters = {
  userLastCreditRequestStatus: ({ userLastCreditRequestStatus }) => userLastCreditRequestStatus,

  wasCreditRequested: ({ wasCreditRequested }) => wasCreditRequested,

  platformName: ({ platformName }) => platformName,

  products: ({ products }) => products,

  isScoreBuilderCredit: (state) => {
    const isValidCredit = checkCredit(state.userLastCreditRequestStatus);

    if (isValidCredit) {
      const { category } = state.userLastCreditRequestStatus.product;
      return category === PRODUCT_CATEGORY.SCORE_BUILDER;
    }

    return false;
  },

  isPlatformCredit: (state) => {
    const isValidCredit = checkCredit(state.userLastCreditRequestStatus);

    if (isValidCredit) {
      const { category } = state.userLastCreditRequestStatus.product;
      return category === PRODUCT_CATEGORY.PLATFORM;
    }

    return false;
  },

  activeCredit: (state) => {
    const isValidCredit = checkCredit(state.userLastCreditRequestStatus);

    if (isValidCredit) {
      const { status } = state.userLastCreditRequestStatus;
      const activeCreditStatus = [
        PRODUCT_STATUS.PENDING,
        PRODUCT_STATUS.APPROVED,
      ];

      return activeCreditStatus.includes(status);
    }

    return false;
  },

  rejectedLoan: (state) => {
    const isValidCredit = checkCredit(state.userRejectedLoan);
    const { status = null } = state.userRejectedLoan;

    if (isValidCredit && status === PRODUCT_STATUS.REJECTED) {
      const {
        rejected_reason: rejectionReason,
        forbidden_request_date: forbiddenUntil,
        product: { category },
        rejected_view_seen: seen,
        uuid: id,
      } = state.userRejectedLoan;
      return {
        category,
        status,
        rejectionReason: rejectionReason || {},
        forbiddenUntil,
        seen,
        id,
      };
    } else {
      return {};
    }
  },

  selectedProduct: ({ selectedProduct }) => selectedProduct,

  nextUrl: ({ nextUrl }) => nextUrl,

  blockedProduct: ({ blockedProduct }) => blockedProduct,

  isBlockedProduct: ({ blockedProduct }) => {
    if (!blockedProduct) {
      return false;
    }
    return Object.keys(blockedProduct).some((property) => blockedProduct[property] !== null);
  },

  isSelectedProduct: ({ selectedProduct }) => !!Object.keys(selectedProduct).length,
};

export default getters;
