import AriesSdk from '@lana/b2c-mapp-sdk';

import { logError } from '../../lib/logger.js';
import getMockUserData from '../../services/utils/getMockUserData.js';

/**
 * @description - Fetch user
 * @returns {Promise<void | IUserData>} - User Data
 * @memberof AriesService
 * @see {@link https://github.com/lana/b2c-mapp-sdk/blob/master/docs/user.md#ariessdkfetchuser}
 */
const fetchUser = async () => {
  try {
    if (import.meta.env.DEV) {
      const user = getMockUserData();
      return user;
    } else {
      const user = await AriesSdk.fetchUser();
      return user;
    }
  } catch (error) {
    logError(
      'ERROR: Aries Service - fetchUser() - error: ',
      error,
    );

    throw error;
  }
};

export default fetchUser;
